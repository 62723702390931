import jump from 'jump.js';

import trackScroll from '@utils/trackScroll';
import { getElementError } from '@testing-library/dom';

// Google Analytics

function navigationEvent(action, label, location) {
  if ('dataLayer' in window) {
    const event = {
      event: 'SendEvent',
      eventCategory: 'Sticky Navigation',
      eventAction: action,
      eventLabel: label,
    };
    if (location) {
      event.eventCallback = () => {
        window.location = location;
      };
      event.eventTimeout = 1000;
    }
    window.dataLayer.push(event);
  }
}

// Sticky navs

type Section = {
  link: HTMLElement,
  target: HTMLElement,
};

function getElementBottom(el: HTMLElement) {
  return el ? Math.floor(el.getBoundingClientRect().bottom) : 0;
}

function getSection(link: HTMLElement): Section {
  const href = link.getAttribute('href');
  let target = null;
  // target may not begin with #
  if (href && href.charAt(0) === '#') {
    target = document.getElementById(href.replace(/^#/i, ''));
  }
  return {
    link,
    target,
  };
}

function handleStickyNavClick(
  event: MouseEvent,
  nav: HTMLElement,
  header: HTMLElement,
) {
  const { target } = event;
  if (!(target instanceof HTMLAnchorElement)) {
    return;
  }

  const href = target.getAttribute('href');
  if (!href) {
    return;
  }
  const isJump = href.charAt(0) === '#';
  if (isJump) {
    // If it's a jump we do smooth scrolling
    // Figure out how much to offset the jump by, and subtract 1px
    // to account for the scroll handler which marks active items
    const offset = getElementBottom(header) + nav.offsetHeight - 1;
    navigationEvent('Click', href);
    jump(href, { offset: -offset });
    event.preventDefault();
    return;
  }

  // If it's not a jump, we need to check for google tag manager before capturing the click
  if (
    'google_tag_manager' in window &&
    typeof window.google_tag_manager !== 'undefined'
  ) {
    // This is a link to a different page
    navigationEvent('Click', href, href);
    event.preventDefault();
  }
}

function initStickyNav(container: HTMLElement) {
  const nav = container.querySelector('nav');
  const header = document.querySelector('header');
  if (!(nav instanceof HTMLElement)) {
    return;
  }
  const links = Array.from(nav.querySelectorAll('.js-sticky-nav-link'));
  const button = nav.querySelector('.js-sticky-nav-btn');
  const sections = links.map(getSection);
  let lastTop = 0;

  if (button instanceof HTMLAnchorElement) {
    const { redirect } = button.dataset;

    if (redirect) {
      button.addEventListener('click', () => {
        setTimeout(() => {
          window.location = redirect;
        }, 2000);
      });
    }
  }

  nav.addEventListener('click', (event: MouseEvent) =>
    handleStickyNavClick(event, nav, header),
  );

  const onScroll = () => {
    let headerBottom = getElementBottom(header);
    let navHeight = 0;

    // Figure out if our situation is sticky
    const containerTop = container.getBoundingClientRect().top;
    const mainNav = document.querySelector('.main-navigation');
    const categoryNav = document.querySelector('.category-navigation');

    // AB Test - GG Nav Moments Update
    if (
      document.querySelector('body.ab-gg-nav-update') instanceof HTMLElement
    ) {
      let categoryBot = categoryNav.getBoundingClientRect().bottom;
      // top of sticky nav higher than bottom of category
      if (containerTop < categoryBot) {
        if (categoryBot !== 0 && containerTop > 0) {
          nav.classList.remove('is-sticky');
          navHeight = 0;
        } else {
          let newHeaderBottom = getElementBottom(mainNav);
          nav.style.top = `${newHeaderBottom}px`;
          nav.classList.add('is-sticky');
          navHeight = nav.offsetHeight;
        }
      } else {
        nav.classList.remove('is-sticky');
        navHeight = 0;
      }
    } else if (containerTop - headerBottom < 0) {
      if (headerBottom !== lastTop) {
        nav.style.top = `${headerBottom}px`;
        lastTop = headerBottom;
      }
      nav.classList.add('is-sticky');
      navHeight = nav.offsetHeight;
    } else {
      nav.classList.remove('is-sticky');
      navHeight = 0;
    }

    // Mark the correct section as active
    let firstNegative = true;
    for (let i = sections.length - 1; i >= 0; i -= 1) {
      const { link, target } = sections[i];

      // some sticky navs don't have active sections on pages
      // e.g external links, no anchors on the same page
      // these targets will be null
      if (target instanceof HTMLElement) {
        const targetY =
          target.getBoundingClientRect().top - headerBottom - navHeight;

        if (targetY <= 0 && firstNegative === true) {
          link.classList.add('is-active');
          link.setAttribute('aria-current', 'true');
          firstNegative = false;
        } else {
          link.classList.remove('is-active');
          link.setAttribute('aria-current', 'false');
        }
      }
    }
  };

  // AB Test -  GG Nav Moments Update
  // listen for class change on nav
  if (document.querySelector('body.ab-gg-nav-update') instanceof HTMLElement) {
    const mainNav = document.querySelector('.main-navigation');
    const categoryNav = document.querySelector('.category-navigation');
    let prevState = nav.classList.contains('is-sticky');
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const { target } = mutation;

        if (mutation.attributeName === 'class') {
          const currentState = mutation.target.classList.contains('is-sticky');
          if (prevState !== currentState) {
            prevState = currentState;

            if (currentState) {
              // nav is sticky
              categoryNav.classList.remove('is-expanded');
              setTimeout(() => {
                categoryNav.style.display = 'none';
                const categoryNavTriggers = Array.from(
                  document.querySelectorAll(
                    '.category-navigation__triggers-list li',
                  ),
                );
                categoryNavTriggers.forEach((trigger) => {
                  trigger.classList.remove('is-active');
                });
                let newHeaderBottom = getElementBottom(mainNav);
                nav.style.top = `${newHeaderBottom}px`;
              }, 100);
            } else {
              // removed
              // Set active category tab
              let activeCategoryNav = categoryNav.querySelector(
                'div.is-active',
              );
              if (
                activeCategoryNav.classList.contains(
                  'category-navigation__list--software',
                )
              ) {
                document
                  .querySelector('.category-navigation__item--software')
                  .classList.add('is-active');
              } else if (
                activeCategoryNav.classList.contains(
                  'category-navigation__list--products',
                )
              ) {
                document
                  .querySelector('.category-navigation__item--products')
                  .classList.add('is-active');
              }
              categoryNav.classList.add('is-expanded');
              categoryNav.style.display = 'block';
            }
          }
        }
      });
    });
    observer.observe(nav, { attributes: true });
  }

  setTimeout(() => trackScroll(window, onScroll), 100);
}

// Initialize navigations
const stickyContainers:Array<HTMLDivElement> = Array.from(
  document.querySelectorAll('.js-sticky-nav'),
);
stickyContainers.forEach((el: HTMLElement) => initStickyNav(el));
